<template>
  <div class="bg-grey">
    <Header>
      <template #afterLogo>
        <NuxtLink :to="{name: 'account-dashboard'}" class="ml-auto text-xs lg:text-sm font-semibold">
            Dashboard
        </NuxtLink>
      </template>
    </Header>
    <div class="h-full mx-auto bg-grey pt-[60px]">
      <div class="w-full xl:pt-8">
        <div class="flex flex-col 2xl:flex-row">
          <div class="w-full xl:max-w-[420px] 2xl:max-w-[468px] xl:fixed">
              <slot name="sidebar"></slot>
          </div>
          <div class="w-full flex-1 relative">
              <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Header from '@/layouts/AccountHeaderLayout.vue'

useHead({
  title: 'Timeline',
  htmlAttrs: {
    lang: 'en',
    class: 'timeline-layout'
  },
  link: [
    {
      rel: 'apple-touch-icon',
      sizes: "180x180",
      href: '/apple-touch-icon.png'
    },
    {
      rel: 'icon',
      sizes: "32x32",
      href: '/favicon-32x32.png'
    },
    {
      rel: 'icon',
      sizes: "16x16",
      href: '/favicon-16x16.png'
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest'
    },
  ],
  meta: [
    { name: 'robots', content: 'noindex, nofollow'}
  ],
});
</script>

